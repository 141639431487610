#Collaborator {
    padding-bottom: 60px;

    .col-12 {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #858585;
        max-width: 460px;
    }
}

@media (max-width: 992px) {
    #Collaborator {
        padding: 80px 0;
        padding-top: 0;

        img {
            width: 100%;
            object-fit: cover;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
            max-width: 100%;
        }

        .col-12 {
            text-align: center;
        }
    }
}