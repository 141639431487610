#Advantages {
    .card-container {
        background: linear-gradient(180deg, #F8F3FF 0%, rgba(255, 255, 255, 0) 66.63%);
        border-radius: 50px;
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 45px 45px 28px 45px;

        ._card {
            &__title {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 24px;
                line-height: 25px;
                text-align: center;
                margin: 30px 0;
                letter-spacing: -0.02em;
            }

            &__description {
                max-width: 350px;
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #858585;
            }
        }
    }
}

@media (max-width: 992px) {
    #Advantages {
        .card-container {
            margin-top: 0px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            padding: 25px 25px 16px 25px;
            background: none;


            ._card {
                &__title {
                    font-size: 18px;
                    margin-bottom: 15px;
                }

                &__description {
                    font-size: 14px;
                }
            }
        }
    }
}