#Contact {
    padding: 120px 0;

    input,
    textarea {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
        padding: 12px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        resize: none;
        outline: none !important;

        &::placeholder {
            color: #858585;
        }
    }

    label {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        color: #858585;
        font-family: 'Montserrat', sans-serif;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        gap: 40px;

        img {
            margin-top: 2px;
        }

        h2 {
            font-family: 'Montserrat', sans-serif;  
            font-weight: 700;
            font-size: 24px;
            letter-spacing: -0.02em;

            a{
                text-decoration: none;
                color: #232323;
                font-family: 'Montserrat', sans-serif;  
            }
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #858585;
        }

        &>div {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 23px;
        }
    }
}

@media (max-width: 768px) {
    #Contact {
        padding: 80px 0;

        input,
        textarea {
            font-size: 14px;
        }

        label {
            font-size: 12px;
        }

        .contact-info {
            h2 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }

            &>div:first-child h2 {
                margin-top: 6px;
            }
        }
    }
}