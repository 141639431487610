#Footer {
    background: var(--primary-color);
    padding: 45px 0;

    * {
        color: #D197FF;
        font-size: 12px;
        font-weight: 500;
        font-size: 14px;
    }

    h5 {
        font-size: 16px;
    }

    a{
        text-decoration: none;
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            line-height: 36px;
        }
    }

    .social {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border: 0.729px solid rgba(141, 144, 147, 0.25);
            border-radius: 3.6px;
            text-decoration: none;

            i {
                font-size: 18px !important;
            }
        }
    }
}

@media (max-width: 768px) {
    #Footer {
        text-align: center;

        .social {
            justify-content: center;
        }
    }
}