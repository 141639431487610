@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #232323;
}

:root {
  --primary-color: #581a92;
}


.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .container-md {
    padding: 0 20px !important;
  }
  .title {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
}
