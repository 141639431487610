#Select {
    width: 100%;
    height: 50px;
    margin-bottom: 40px;
    *{
        color: #000 !important;  
    }
    .select-box {
        position: relative;
        height: 100%;
        background: #F5F5F5;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    label {
        font-family: 'Inter';
        font-weight: 100;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #232323;
        margin-bottom: 5px;
    }

    img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        position: absolute;
        left: 15px;
    }

    select {
        height: 100%;
        background: transparent;
        border: none !important;
        outline: none !important;
        padding-left: 40px;
        padding-right: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #232323;
    }

}