#Calculator {
    z-index: 2;
    padding: 45px;
    background: #FFFFFF;
    border-radius: 36px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    width: 100%;

    * {
        font-family: 'Inter';
    }

    h6 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.02em;
        color: #232323;
    }

    button {
        background: #F1A828;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.01em;
        border-radius: 16px;
        width: 100% !important;
        padding: 20px 44px !important;
    }
}

@media (max-width: 992px) {
    #Calculator{
        box-shadow: 0px 16px 70px rgba(0, 0, 0, 0.05);
        position: absolute;
        margin-top: 30px;
    }
}