#Header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    transition: 0.3s ease;
    animation: setAbsolute 0s ease forwards;

    &.fixed {
        position: fixed;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        background: var(--primary-color) !important;
        animation: setSticky 0.3s ease forwards;
    }

    @keyframes setSticky {
        0% {
            margin-top: -80px;
        }

        100% {
            margin-top: 0;
        }
    }

    @keyframes setAbsolute {
        0% {
            margin-top: 80px;
        }

        100% {
            margin-top: 0px;
        }
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;

        nav {
            margin-left: 50px;
        }

        img {
            margin-bottom: 3px;
        }

        nav ul {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            gap: 30px;

            a {
                text-decoration: none;
                color: #fff;
                font-family: 'Inter';
                font-weight: 500;
                font-size: 14px;
            }
        }

        #toggle {
            background: none;
            border: none;
        }

        .lang-button {
            width: 30px;
            height: 18px;
            background: none;
            border: none;
            display: flex;
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                margin-right: 10px;
            }

            i {
                color: #fff;
            }

            .lang-dropdown {
                position: absolute;
                top: calc(100% + 10px);
                background: #fff;
                border-radius: 6px;
                right: -30px;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                visibility: hidden;
                opacity: 0;
                transition: 0.3s ease;
                margin-top: 10px;

                &.active {
                    visibility: visible;
                    opacity: 1;
                    margin-top: 0;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    padding: 10px 20px !important;


                    li {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin: 0 !important;

                        span {
                            font-size: 14px;
                        }

                        img {
                            width: 25px;
                            object-fit: cover;
                            height: 15px;
                            margin-right: 10px;
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: 768px) {
    #Header {
        header {
            justify-content: space-between;
        }

        .lang-button {
            width: 100% !important;

            &>img {
                width: 25px !important;
                height: 15px !important;
            }
        }

        .lang-dropdown {
            width: 94px;
            left: 0px;
            right: auto;
        }

        nav {
            margin: 0 !important;
            position: fixed;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            left: -60%;
            top: 0;
            height: 100vh;
            background: var(--primary-color);
            width: 60%;
            padding: 80px 0 20px 20px !important;
            transition: 0.3s ease;

            &.active {
                left: 0;
            }

            .logo {
                position: absolute;
                top: 20px;
            }

            ul {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 20px !important;
            }

            #close {
                background: none;
                border: none;
                position: absolute;
                right: 20px;
                top: 25px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}