#button {
    background: #F1A828;
    border-radius: 8px;
    padding: 14px 50px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border: none;
    transition: 0.3s ease;
    border: 2px solid transparent;

    a {
        text-decoration: none;
        color: #fff;
        width: 100%;
        height: 100%;
    }

    &:hover {
        background: transparent;
        border: 2px solid #F1A828;
        color: #F1A828;


        a {
            color: #F1A828;
        }
    }
}

@media (max-width: 768px) {
    #button {
        font-size: 14px;
        padding: 12px 30px;
    }
}