#Hero {
    background-color: #581A92;
    border-radius: 0px 0px 120px 120px;
    background-position: right center;
    height: 678px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .row .col-12:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    * {
        font-family: "Montserrat", sans-serif;
        color: #fff;
    }

    &>img {
        position: absolute;
        right: 0px;
        height: 80%;
    }

    h1 {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 20px;
        max-width: 750px;
        width: 100%;
    }

    p {
        max-width: 600px;
        width: 100%;
        font-size: 18px;
        line-height: 25px;
    }

    button {
        margin: 10px 0;
    }
}


@media (max-width: 992px) {
    #Hero {
        position: relative;
        height: 650px;
        background: linear-gradient(180deg, rgba(125, 25, 203, 0.8) 0%, rgba(49, 0, 88, 0.9) 100%);
        border-radius: 0px 0px 30px 30px;

        &>img {
            display: none;
        }

        .row .col-12:first-child {
            text-align: center;
            align-items: center;
        }

        h1 {
            font-size: 30px;
            line-height: 34px;
            padding: 0 20px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}